/* IL Style Variables */
@import '../colors';

$primary:                           #121f32;
$secondary:                         #fd8954;
$tertiary:                          #781c16;
$light:                             #d5d6d8;

$font-family-base: 'Open Sans', sans-serif;

/* Primary Page-Styling Variables */
$body-bg:                           #777;
$body-color:                        #222;
$link-color:                        $secondary;

$navbar-bg:                         $light;

//Home Page Styling
$jumbotron-bg:                      rgba(#344759, 0.9);

//Process Page Styling
$process-page-themes: (
  "claim": $primary,
  "holder": $tertiary
) !default;

$btn-action-bg:                     $primary;
$btn-pop-bg:                        $secondary;

$dropdown-bg:                       #fff;
$dropdown-hover-bg:                 $secondary;
$dropdown-divider-bg:               $light;

$dropdown-link-color:               $primary;
$dropdown-link-hover-color:         $primary;
$dropdown-link-hover-bg:            $secondary;

$mobile-navbar-bg:                  #555;
$static-page-header-color:          $secondary;
$static-page-subtitle-color:        #222;

/* Image Size & Image Source Variables */
$banner-image-url:                  url('/images/banner.jpg');
$body-background-image-url:         url('/images/bg_texture.png');

$claim-header-logo-url:             url('/images/il_process_logo.png');
$claim-header-logo-width:           190px;
$claim-header-logo-height:          75px;

$holder-header-logo-url:            $claim-header-logo-url;
$holder-header-logo-width:          $claim-header-logo-width;
$holder-header-logo-height:         $claim-header-logo-height;

$home-logo-url:                     url('/images/home_logo.png');
$home-logo-width:                   420px;
$home-logo-height:                  80px;
$home-logo-resize-value-map:        (width: 83px,
                                     height: $home-logo-height,
                                     background-image: $home-logo-url);

$alternate-home-logo-url:                url('/images/treasurer_logo.png');
$alternate-home-logo-width:              420px;
$alternate-home-logo-height:             80px;
$alternate-home-logo-resize-value-map:   (width: 83px,
                                          height: $alternate-home-logo-height,
                                          background-image: $alternate-home-logo-url);

$footer-logo-url:                   url('/images/il_seal.png');
$footer-logo-width:                 135px;
$footer-logo-height:                135px;

$treasurer-logo-url:                url('/images/treasurer_logo.png');
$treasurer-logo-width:              420px;
$treasurer-logo-height:             80px;
$treasurer-logo-resize-value-map:   (width: 83px,
                                     height: $treasurer-logo-height,
                                     background-image: $treasurer-logo-url);

$claimform-image-url:               url('/images/il_claim_form_img.png');

$icon-email-url:                    none;
$icon-facebook-url:                 url('/images/icon_facebook.png');
$icon-instagram-url:                url('/images/icon_instagram.png');
$icon-twitter-url:                  url('/images/icon_twitter.png');
$icon-youtube-url:                  url('/images/icon_youtube.png');
$icon-linkedin-url:                 none;

$social-icon-width:                 25px;
$social-icon-height:                25px;

.bg-fade-gray {
  background-color:  #f5f5f5 !important;
}

.bg-success{
  a {
    color: #0d94e2;
    &:hover{
      color: darken(#0d94e2, 15%)
    }
  }
}

.ach-div {
  background-color: $secondary;
  margin-top: 20px;
  a {
    color: #0d94e2;
  }
}